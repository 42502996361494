<template>
  <div class="trial-box pr" v-cloak>
    <!-- 您的贷款申请已提交 -->
    <div class="top-box u-flex u-row-between">
      <div class="u-flex-col">
        <div class="top-submit">
          贷款初审
          <span>已通过</span>，<br />
          等待合作方电话复审
        </div>
        <div class="top-amount">
          预估额度
          <span>{{ loanAmount }}元</span>
        </div>
      </div>
      <img
        class="top-img"
        src="@/assets/images/icon_check_markMax.png"
        alt=""
      />
    </div>

    <div class="trial-elem">
      <div class="trial-item">
        <p
          class="u-text-center origan-matching"
          v-if="applyId === '0' || !productName"
          style="width: 100%"
        >
          正在匹配中...
        </p>
        <template v-else>
          <!-- 有客服电话，机构和客服电话展示内容 -->
          <template v-if="callMobile">
            <!-- 机构 -->
            <div class="origan-item">
              <img
                class="origan-img"
                :src="
                  productLogo
                    ? productLogo
                    : '../assets/images/trial_success.png'
                "
                alt=""
              />
              <div class="origan-right">
                <div class="origan-title">
                  合作方将于30分钟内联系您(9:00-18:00)
                </div>
                <div class="origan-name">{{ productName }}</div>
              </div>
            </div>
            <!-- 客服电话 -->
            <div class="support-staff">
              <div class="staff-tit">将由该客户经理为您服务</div>
              <div class="staff-box">
                <img
                  class="staff-img"
                  src="../assets//images/icon_avatar.png"
                  alt=""
                />
                <div class="staff-right">
                  <div class="staff-r-top">{{ callMobile }}</div>
                  <div class="staff-r-desc">请注意接听该号码来电</div>
                </div>
              </div>
            </div>
          </template>
          <!-- 没有客服电话，存机构展示内容 -->
          <template v-else>
            <div class="origan-title">合作方将于30分钟内联系您(9:00-18:00)</div>
            <!-- 机构 -->
            <div class="staff-box">
              <img
                class="staff-img"
                :src="
                  productLogo
                    ? productLogo
                    : '../assets/images/trial_success.png'
                "
                alt=""
              />
              <div class="staff-right">
                <div class="staff-r-top">{{ productName }}</div>
                <div class="staff-r-desc">
                  为保障电话复查通过，请注意接听来电
                </div>
              </div>
            </div>
          </template>
          <div class="trial-desc">
            首次借款需要合作方客户经理电话联系您确认相关信息，请注意接听
          </div>
        </template>

        <!-- 下载APP，查看实时放款进度 -->
        <van-button
          v-if="isRedirectProductsPage === 0"
          class="trial-btn"
          type="primary"
          round
          @click="handleDownload"
        >
          下载APP，查看实时放款进度
        </van-button>

        <!-- 我知道了 -->
        <van-button
          v-else
          class="trial-btn"
          type="primary"
          round
          @click="handleIGotIt"
        >
          {{ `我知道了 (${countDC}s)` }}
        </van-button>
      </div>
    </div>

    <!-- 产品 -->
    <div v-if="productInfoObj15" class="product-elem">
      <h5 class="product-elem-title">
        根据您的信用情况,您还可以快速在以下产品获得贷款
      </h5>

      <!-- 产品展示 -->
      <div class="product-display">
        <div class="u-flex" @click="handleProductClick(productInfoObj15, 15)">
          <img
            class="product-img"
            :src="
              productInfoObj15.imgUrl
                ? productInfoObj15.imgUrl
                : '../assets/images/trial_success.png'
            "
          />
          <div class="product-title u-flex-col u-row-between">
            <div class="u-flex">
              <h4>{{ productInfoObj15.productName }}</h4>
              <van-tag
                v-for="(item, index) in productInfoObj15.tagList"
                :key="index"
                color="rgba(53,99,250,0.1)"
                text-color="#3563FA"
              >
                {{ item }}
              </van-tag>
            </div>
            <p class="credit-for-you">
              为您授信
              <span>
                {{ productInfoObj15.creditAmount }}
              </span>
              元贷款额度
            </p>
          </div>
        </div>
        <ul class="u-flex u-row-between product-display-ul u-text-left">
          <li class="u-flex-col">
            <p>{{ `${productInfoObj15.timeLimitLoanRate}%` }}</p>
            <small>限时综合年化</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.loanPeriod }}</p>
            <small>最高可借期限(月)</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.maxAmount }}</p>
            <small>最高借款金额 (元)</small>
          </li>
        </ul>
        <p class="originate-loan">
          已成功向
          <span>{{ productInfoObj15.loanNumber }}</span>
          人发起贷款
        </p>
      </div>
    </div>

    <!-- 底部内容（贷款流程，底部文案） -->
    <div class="bottom-box">
      <!-- 贷款流程 -->
      <div class="loan-process-con">
        <h4>贷款流程</h4>
        <ul class="u-flex u-row-between u-col-stretch loan-process-ul">
          <!-- 风控系统审核 -->
          <li class="loan-process-li">
            <img class="loan-img" src="../assets/images/icon_fkxtsh6.png" />
            <h6 class="loan-tit">风控系统审核</h6>
            <div class="u-flex u-row-center">
              <img
                class="icon-img"
                src="../assets/images/icon_check_mark.png"
              />
              <span class="green">已通过</span>
            </div>
            <p class="loan-btn">可放款</p>
          </li>
          <!-- 电话复审 -->
          <li class="loan-process-li">
            <img class="loan-img" src="../assets/images/icon_jxz6.png" />
            <h6 class="loan-tit">电话复审</h6>
            <div class="u-flex u-row-center">
              <span class="green">进行中...</span>
            </div>
            <p class="loan-btn">注意接听</p>
          </li>
          <!-- APP取款 -->
          <li class="loan-process-li">
            <img class="loan-img" src="../assets/images/icon_appqk6.png" />
            <h6 class="loan-tit">APP取款</h6>
            <div class="u-flex u-row-center">
              <span class="green">等待电话复审...</span>
            </div>
            <p class="loan-btn">注意接听</p>
          </li>
        </ul>
      </div>

      <!-- 热线电话 -->
      <p class="tel-con u-text-center">
        <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
        <a href="tel:4008818897" class="telnum">客服热线: 400-881-8897</a>
      </p>

      <!-- 底部 -->
      <div class="bottom u-text-center">
        综合年化6%-24%，最终结果以审批为准<br />
        贷款有风险，借款需谨慎<br />
        请根据个人能力合理贷款，理性消费
      </div>
    </div>

    <!-- 产品弹窗 -->
    <template v-if="productInfoObj16">
      <van-popup
        v-model="show"
        class="product-layer u-flex-col u-col-center"
        round
        :close-on-click-overlay="false"
      >
        <h4>为您匹配到合适的贷款产品</h4>
        <img :src="productInfoObj16.imgUrl" />
        <h5>{{ productInfoObj16.productName }}</h5>
        <p>
          您最高可借
          <span>{{ productInfoObj16.maxAmount }}</span>
        </p>
        <div
          class="btn-now u-text-center"
          @click="handleProductClick(productInfoObj16, 16)"
        >
          立即申请
        </div>
        <div class="btn-not u-text-center" @click="show = false">先不用</div>
      </van-popup>
    </template>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TrialSuccessTl7",
  data() {
    return {
      source: this.$route.query.source, // 渠道标识
      loanAmount: this.$route.query.loanAmount, // 金额
      applyId: this.$route.query.applyId, // 进件申请id(0为不成功，无需调用接口查询机构信息,不为0需要查询)
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程1是 0否
      backSkipUrl: this.$route.query.backSkipUrl,
      productLogo: "", // 机构ICON
      productName: "", // 机构名称
      callMobile: "", // 客服电话
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
      show: false, // 产品弹窗
      // showPage: 15,H5进件完成页(用于页面底部的产品详情查询),16,投放页完成下载APP(用于页面弹窗的产品详情查询),
      productInfoObj15: null,
      productInfoObj16: null,
      countDC: 3, // 贷超倒计时
      timer: null, // 计时器
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    // 如果进件申请id不为0则查询机构信息
    if (this.applyId !== "0") {
      this.getMechanismInfo();
    }

    this.getDownloadUrl();

    // 获取页面底部的产品详情信息
    this.getProductInfo(15);

    // 获取弹窗内部产品详情信息
    this.getProductInfo(16);

    if (this.isRedirectProductsPage === 1) {
      this.timer = setInterval(() => {
        this.countDC--;
        if (this.countDC === 0) {
          clearInterval(this.timer);
          this.handleIGotIt();
        }
      }, 1000);
    }
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          // 有弹窗内部推荐产品的时候才允许弹窗
          if (this.productInfoObj16) {
            this.show = true;
          }
        }
      }
    },

    // 我知道了按钮点击
    handleIGotIt() {
      var timestamp = new Date().getTime();
      window.location.href = `/productList.html?source=${this.source}&tsp=${timestamp}`;
    },
    // 获取机构信息
    getMechanismInfo() {
      API_Common.getMechanismInfo(this.applyId).then((res) => {
        if (res?.code === 200) {
          // 进件是否推送成功;1:成功(会返回对应的产品信息)、0:失败(不会返回产品信息)
          if (res.data.hasPushSuccess === 1) {
            this.productName = res.data.productName;
            this.productLogo = res.data.productLogo;
            this.callMobile = res.data.callMobile;
          }
        } else {
          this.$toast(res?.msg);
        }
      });
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel, true).then((res) => {
        if (res?.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 获取产品信息
    getProductInfo(showPage) {
      API_Common.getProductInfo({ showPage }).then((res) => {
        if (res?.code === 200) {
          if (res.data) {
            this[`productInfoObj${showPage}`] = res.data;
          }
        } else {
          this.$toast.fail(res?.msg);
        }
      });
    },

    // 监听产品点击
    handleProductClick(obj, showPage) {
      // 埋点
      API_Common.buried({
        productId: obj.productId,
        showPage,
      }).then((res) => {
        if (200 === res?.code) {
          setTimeout(() => {
            var timestamp = new Date().getTime();
            window.location.href = obj.linkUrl + "?tsp=" + timestamp;
          }, 500);
        }
      });
    },

    // 给浏览器的访问记录添加一条此页面的访问记录
    addHistory() {
      window.history.pushState(null, null, "productList.html");
    },
  },
};
</script>

<style lang="scss" scoped>
.trial-box {
  height: 100vh;
  overflow: auto;

  .top-box {
    padding: 0.5rem 0.84rem 1.25rem 0.3rem;
    background: linear-gradient(180deg, #ffffff 0%, #ebf4ff 100%);

    .top-submit {
      font-size: 0.48rem;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #191919;
      line-height: 0.68rem;

      > span {
        color: #4f78f4;
      }
    }

    .top-amount {
      font-size: 0.28rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #191919;
      line-height: 0.41rem;
      margin-top: 0.2rem;

      > span {
        color: #f94f43;
      }
    }

    .top-img {
      display: block;
      width: 1.48rem;
      height: 1.48rem;
    }
  }

  .trial-bg {
    display: block;
    width: 100%;
    height: 7.2rem;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #3563fa, rgba(242, 245, 250, 0) 90%);
    background-size: 100% 7.2rem;
    z-index: 0;
  }

  .trial-elem {
    position: relative;
    z-index: 1;
    margin-top: -0.75rem;
    padding: 0.4rem 0.3rem;
    background: #ffffff;
    border-radius: 0.4rem 0.4rem 0 0;
  }

  .trial-item {
    .trial-desc {
      font-size: 0.24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #f94f43;
      margin-top: 0.2rem;
    }
  }

  .origan-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.24rem;

    .origan-img {
      width: 0.86rem;
      height: 0.86rem;
      background: #ffffff;
      border-radius: 0.12rem;
      margin-right: 0.14rem;
    }

    .origan-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }
  }

  .origan-matching {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #111c31;
    line-height: 0.4rem;
  }

  .origan-title {
    font-size: 0.3rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #111111;
    line-height: 0.45rem;
  }

  .origan-name {
    font-size: 0.24rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #7e7e7e;
    line-height: 0.45rem;
  }

  .support-staff {
    background: #f8f8f8;
    border-radius: 0.2rem;
    padding: 0.3rem;

    .staff-tit {
      font-size: 0.24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #7e7e7e;
      line-height: 0.45rem;
    }
  }

  .staff-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff1f0;
    border-radius: 0.16rem;
    border: 0.04px solid #f94f43;
    padding: 0.3rem 0.28rem;
    margin-top: 0.24rem;

    .staff-img {
      width: 0.78rem;
      height: 0.78rem;
      border-radius: 50%;
      margin-right: 0.2rem;
    }

    .staff-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .staff-r-top {
        font-size: 0.36rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #191919;
        line-height: 0.41rem;
        margin-bottom: 0.1rem;
      }

      .staff-r-desc {
        font-size: 0.24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7e7e7e;
        line-height: 0.41rem;
      }
    }
  }

  .trial-btn {
    display: block;
    width: 100%;
    background: #4f78f4;
    border-color: #4f78f4;
    font-size: 0.32rem;
    margin-top: 0.48rem;
  }

  .product-elem {
    margin: 0.2rem 0;
    padding: 0.3rem;

    .product-elem-title {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
    }

    .product-display {
      background: #fff;
      border-radius: 0.24rem;
      margin-top: 0.24rem;
      padding: 0.3rem;
      box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08);

      > div {
        padding-bottom: 0.24rem;
        border-bottom: 0.02rem solid #e6e7ee;
      }

      .product-img {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 0.2rem;
        margin-right: 0.24rem;
      }

      .product-title {
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: bold;
        color: #111c31;

        .van-tag {
          margin-left: 0.1rem;
          padding: 0.02rem 0.1rem;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          margin-left: 0.12rem;
        }
      }

      .credit-for-you {
        margin-top: 0.05rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }

      .product-display-ul {
        margin: 0.24rem 0 0.4rem;

        p {
          font-size: 0.4rem;
          font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
          font-weight: normal;
          color: #111c31;
        }

        small {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: #868e9e;
        }
      }

      .originate-loan {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }
    }
  }

  .product-layer {
    width: calc(100% - 1.52rem);
    padding: 0.36rem 0.46rem;
    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > img {
      margin: 0.48rem 0 0.16rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.2rem;
    }

    > h5 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > p {
      margin: 0.16rem 0 0.3rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
      > span {
        color: #fb5e1e;
      }
    }

    > div {
      width: 100%;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.48rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
    }

    > .btn-now {
      background: #3563fa;
      color: #ffffff;
    }

    > .btn-not {
      color: #868e9e;
    }
  }

  .bottom-box {
    padding: 0.3rem;
    .tel-con {
      .telnum {
        font-size: 0.22rem;
      }
    }
    .loan-process-con {
      > h4 {
        font-size: 0.32rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #191919;
        margin-bottom: 0.2rem;
      }

      .loan-process-ul {
        margin-left: -0.1rem;
        margin-right: -0.1rem;
      }

      .loan-process-li {
        background: #f8f8f8;
        border-radius: 0.16rem;
        flex: 1;
        margin: 0 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2rem 0.18rem;

        .loan-img {
          width: 0.48rem;
          height: 0.48rem;
          margin-bottom: 0.17rem;
        }

        .loan-tit {
          font-size: 0.28rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #110f1a;
          line-height: 0.34rem;
          margin-bottom: 0.12rem;
        }

        .icon-img {
          width: 0.3rem;
          height: 0.3rem;
          margin-right: 0.1rem;
        }

        .green {
          font-size: 0.24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #3ab768;
          line-height: 0.34rem;
        }

        .loan-btn {
          background: #4f78f4;
          border-radius: 0.06rem;
          font-size: 0.24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          margin-top: 0.25rem;
          width: 100%;
          padding: 0.12rem 0.1rem;
          text-align: center;
        }
      }
    }

    .bottom {
      margin: 0.6rem auto;
      white-space: pre-line;
      font-size: 0.24rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 0.36rem;
    }
  }
}
</style>
